<template>
  <v-container fluid>
    <v-card class="mb-4" v-for="(r, row_i) of referral" :key="row_i">
      <v-card-title class="heading-text mb-5" v-html="r.title" />
      <v-card-subtitle v-for="(points, index) in r.data" :key="index" class="subtitle-text" v-html="points" />
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      referral: [
        {
          title: this.$t('refer_and_earn'),
          data: [
            this.$t("refer_and_earn_point_1"),
            this.$t("refer_and_earn_point_2"),
          ],
        },
        {
          title: this.$t('share_and_earn'),
          data: [
            this.$t("share_and_earn_point_1"),
            this.$t("share_and_earn_point_2"),
            this.$t("share_and_earn_point_3"),
            this.$t("share_and_earn_point_4"),
          ]
        },
        {
          title: this.$t('engage_and_earn'),
          data: [
            this.$t("engage_and_earn_point_1"),
            this.$t("engage_and_earn_point_2"),
            this.$t("engage_and_earn_point_3"),
            this.$t("engage_and_earn_point_4"),
            this.$t("engage_and_earn_point_5"),
          ]
        }
      ],
    };
  },
};
</script>


<style scoped>
.heading-text {
  word-break: keep-all;
  color: black;
  font-size: 18px !important;
}
.subtitle-text {
  color: black !important;
  font-size: 16px !important;
}
</style>